// ***************************************************************
//JS: lovell-locale.js
// Lovell Rugby website responsive functions - locale form controls (e.g. language, currency and delivery options)
// (c) 2015 Lovell Rugby Limited

// AJAX - Update currency on server
function changeCurrency(currency) {
  // Change the currency and reload the current page
  // Clear price filtering in hash
  var hash = location.hash.replace(/^#/, '');
  if (hash) {
    var filters = hash.split(',');
    var priceRangeRegExp = /pricerange=[0-9\.]+~[0-9\.]+/;
    for (var i=0; i<filters.length;i++) {
      if (priceRangeRegExp.test(filters[i])) {
        var pricerange = filters.splice(i,1);
      }
    location.hash = '#'+filters.join(',');
    }
  }
  doUpdateCurrency(currency, 'basketSummary');
  updateCompactBasket();
}

function doUpdateCurrency() {
  var args = doUpdateCurrency.arguments;
  doAjaxAction({
    action: "currencyUpdate",
    currencyData: args[0],
  }, function(output) {
    $("#"+args[1]).html(output);
 });
}
