$(function() {
  $(document).on('click', '.product-focus__region', function() {
    if (cmdIsPressed) return;
    if ($(this).find('.editing').length) return;
    $('.product-focus__region').removeClass('active').addClass('collapsed');
    $(this).addClass('active').removeClass('collapsed');
    var index = $(this).index();
    $(this).closest('.product-focus').find('.product-focus__image-main').addClass('inactive');
    $(this).closest('.product-focus').find('.product-focus__region-image').removeClass('active');
    $(this).closest('.product-focus').find('.product-focus__region-image').eq(index).addClass('active');
  });
  
  $(document).on('click', '.product-focus__expand-button', function(e) {
    if (cmdIsPressed) return;
    if ($(this).closest('.product-focus__region').hasClass('active')) {
      $('.product-focus__region').removeClass('active').removeClass('collapsed');
      $(this).closest('.product-focus').find('.product-focus__image-main').removeClass('inactive');
      $(this).closest('.product-focus').find('.product-focus__region-image').removeClass('active');
      e.stopPropagation();
    }
  });
});


// Updated version
$(window).on("load", function () {
  $(".product-focus-2__panel").on("click", function () {
    $(".product-focus-2__panel").removeClass("active");
    $(this).addClass("active");
  });
});



