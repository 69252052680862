// *****************************************************************
//JS: lovell-menus.js
// Lovell Rugby website functions for menus
// (c) 2017 Lovell Rugby Limited

$(document).ready(function () {
  // screenWidth is a nasty global and should probably be updated somewhere more core than this.
  $(window)
    .on("resize", function () {
      // Remake megamenu data.
      if (screenWidth != $(window).width()) {
        screenWidth = $(window).width();
        redrawMegaMenu();
      }
    });
  redrawMegaMenu();

  // hover over main menu items
  var config = {
    over: function (e) {
      if (getDeviceType() == 'desktop' || getDeviceType() == 'wide') {
        disableImageZoom();
        $(this).addClass('active');
      }
    },
    timeout: 300,
    out: function() {
      if (getDeviceType() == 'desktop' || getDeviceType() == 'wide') {
        $(this).removeClass('active');
        $('body').css('overflow', 'auto');
      }
    }
  };
  $('#navbar ul.nav > li').hoverIntent(config);


  function redrawMegaMenu() {
    // Only remake megamenu if screen width has changed
    printResponsiveMegaMenus();
    resetMegaMenu();
  }

  function resetMegaMenu() {
    // Reset Mega Menu to its original state
    $('ul.nav li').removeClass('current');
    $('a.mainNav').removeClass('active');
    $('div.mainNav').removeClass('active');
    $('.mainNav').show();
    $('nav.navbar').siblings('div').css('opacity', '1');
    if(getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
      $("ul.nav li:not('.dropdown-header')").css('display', 'block');
      $('#menu-close').addClass('hidden');
      $('#menu-open').removeClass('hidden');
      $('.mainNav').find('.arrow-right').show();
      $('.mainNav').find('.back-button').hide();
      $('.dropdown-menu').find('li:not(.dropdown-header)').hide();
      $('#navbar').removeClass('active');
      $('nav.navbar').removeClass('open');
    } else {
      $('#navbar').show();
    }
    $('li.shopAll').show();
  }

  // close mega menu
  $('#menu-close').on('click', function() {
    $(this).toggleClass('hidden');
    $('#menu-open').toggleClass('hidden');
    $('#navbar').removeClass('active');
    $('nav.navbar').removeClass('open');
    if(getDeviceType() == 'tablet') {
      $('nav.navbar').siblings('div').css('opacity', '1');
    }
    if(getDeviceType() == 'tablet' || getDeviceType() == 'mobile') {
      // Set body position back to default when closing menu, so it's scrollable again
      $('body').css('overflow-y', 'auto');
      $('html').css('overflow-y', 'auto');
    }
  });

  // close mega menu if clicking outside menu area on the tablet site
  $('nav.navbar').siblings('div').on('click', function(e) {
    if(getDeviceType() == 'tablet' && $('#navbar').hasClass('active')) {
      e.preventDefault();
      $('#menu-close').toggleClass('hidden');
      $('#menu-open').toggleClass('hidden');
      $('#navbar').removeClass('active');
      $(this).css('opacity', '1');
      $(this).siblings('div').css('opacity', '1');
      $('body').css('overflow-y', 'auto');
    }
  });

  $('.add-another a').on('click', function() {
    $('#ba_Quantity').val(1);
  });

  //*****************************************************************************************
  // triggered when megamenu header has been clicked
  $(document).on('click', 'a.mainNav, div.mainNav', function(e) {
    var menuExists = 0;
    if($(this).parent().find('div .dropdown-menu').children().length) {
      menuExists = 1;
    }
    if((getDeviceType() == 'mobile' || getDeviceType() == 'tablet') && menuExists == 1) {
      e.preventDefault();
      $(this).parent().siblings().toggle();
      $(this).find('.back-button').toggle();
      $(this).find('.arrow-right').toggle();
      $(this).parent().toggleClass('current');
      if($(this).parent().find('div .dropdown-menu').is(':visible')) {
        $(this).find('.mainNavText').css('text-align', 'center');
      } else {
        $(this).find('.mainNavText').css('text-align', 'left');
      }
      // Toggle sub menu
      $(this).parent().find('div .dropdown-menu').toggle();
    }
    $(this).toggleClass('active');
  });

  $('ul.nav li').on('click', 'div ul.dropdown-menu li.dropdown-header', function(e) {
    if(getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
      e.preventDefault();
      // Menu option becomes back button for sub menu. Toggle back/forward chevrons
      var menuID = $(this).data('menuid');
      // Toggle sub menu
      if($(this).siblings('.' + menuID + ':visible').length > 0) {
        $(this).siblings('.' + menuID).hide();
        $(this).siblings('li.dropdown-header').show();
        $(this).siblings('li.shopAll').show();
        $(this).find('.back-button').hide();
        $(this).find('.arrow-right').show();
        $(this).removeClass('current');
        $(this).parent().parent().parent().find('> a').show();
      } else {
        $(this).siblings('.' + menuID).show();
        $(this).siblings('li.dropdown-header').hide();
        $(this).siblings('li.shopAll').hide();
        $(this).find('.back-button').show();
        $(this).find('.arrow-right').hide();
        $(this).addClass('current');
        $(this).parent().parent().parent().find('> a').hide();
      }
    }
  });

  //*****************************************************************************************
  // add the is-loaded class to footer menu to improve visual loading
  $('#footer-menu').addClass('is-loaded');

  // triggered when expandable menu header has been clicked
  $('#footer-menu ul.top-menu > li > div.menu-header').on('click', function(e) {
    if(getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
      e.preventDefault();
      // toggle sub menu visibility on footer menu
      $(this).closest('li').find('.footer-sub-menu').slideToggle('fast');
      // toggle highlighted background for expanded menu header
      $(this).toggleClass('expanded');
      // toggle plus/minus image
      $(this).find('.plus').toggle(0);
      $(this).find('.minus').toggle(0);
    }
  });


  // remove br tags from menu items
  $('#megaMenuTabs ul.nav li a br').replaceWith(' ');

  // remove dropdown menus if there's no content for them
  $('.dropdown-menu').each(function () {
    if($.trim($(this).html()) == '') {
      $(this).remove();
    }
  });

}); // end doc ready

function printResponsiveMegaMenus() {
  // Initialize Mega Menus
  try {
    for (var i = 1; i <= megaMenuItems.length - 1; i++) {
      printResponsiveMegaMenu(i);
    }
  } catch (e) {
    console.error(`No menus!`);
  }
}

function printResponsiveMegaMenu(menuId) {
  var output = '';
  if(getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
    output += '<ul id="menu' + menuId + '" class="dropdown-menu" role="menu">';
  } else {
    output += '<div id="menu' + menuId + '" class="dropdown-menu">';
  }

  var menuLines = '';
  var inDiv = 0;
  var menuID = 0; // menuID identifies the sub-menu a menu item is in when on the mobile site.
  var previousItemWasABanner = 0;
  for(var i = 0; i < megaMenuItems[menuId].length; i++) {
    var lineItems = new Array();
    var nextMenuItem = megaMenuItems[menuId][i+1];
    var nextLinkLevel = '';
    if (nextMenuItem) {
      var nextItemData = nextMenuItem.split('~');
      nextLinkLevel = nextItemData[2];
    }
    lineItems = newStyleMegaMenuLineResponsive(megaMenuItems[menuId][i], inDiv, menuID, previousItemWasABanner, nextLinkLevel);

    var screenSizeMatches = lineItems[4];
    if (screenSizeMatches) {
      menuLines += lineItems[0];
      inDiv = lineItems[1];
      var newSubMenu = lineItems[2];
      previousItemWasABanner = lineItems[3];
      if (newSubMenu) {
        menuID++;
      }
    }
  }
  output += menuLines;
  if(getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
    output += '</ul>';
  } else {
    output += '    </div>';
    output += '</div>';
  }
  $('#tab' + menuId).html(output);
}

function newStyleMegaMenuLineResponsive(menuLine, inDiv, menuID, previousItemWasABannerOrPage, nextItemLinkLevel) {
  // Outputs HTML for supplied menu item
  var menuItem = '';
  var itemData = [];
  var addClass = ' submenu' + menuID;
  var newSubMenu = 0;
  itemData = menuLine.split('~');
  var linkType = itemData[0];
  var text = itemData[1];
  var linkLevel = itemData[2];
  var link = itemData[3];
  var showOnScreenSize = itemData[4];
  var menuSectionTitle = itemData[5];
  if (menuSectionTitle == "undefined") {
    menuSectionTitle = '';
  }
  var screenSizeMatches = 1;
  if (showOnScreenSize == 'mobile' || showOnScreenSize == 'desktop') {
    screenSizeMatches = 0;
    if ((getDeviceType() == 'mobile' || getDeviceType() == 'tablet') && showOnScreenSize == 'mobile') {
      screenSizeMatches = 1;
    }
    else if ((getDeviceType() == 'desktop' || getDeviceType() == 'wide') && showOnScreenSize == 'desktop') {
      screenSizeMatches = 1;
    }
  }

  if (screenSizeMatches) {
    if (linkType == 'BLANKLINE') {
      if (getDeviceType() == 'desktop' || getDeviceType() == 'wide') {
        menuItem += '<span class="newline">&nbsp;<br /></span>';
      }
    } else if (linkType == 'NEWCOLUMN' || text == 'NEWCOLUMN') {
      if (getDeviceType() == 'desktop' || getDeviceType() == 'wide') {
        if (inDiv) {
          // End current block
          menuItem += '</div>';
        }
        menuItem += '<div class="megaMenuContentBlock menu-column">';
        inDiv = 1;
      }
      previousItemWasABannerOrPage = 0;
    } else if (linkType == 'B' && text) { // Banner
      if (!previousItemWasABannerOrPage) {
        if (inDiv) {
          // End current block
          menuItem += '</div>';
        }
        menuItem += '<div class="megaMenuContentBlock menu-column menu-banner-column">';
      }
      menuItem += '<div class="ffBanner">' + text + '</div>';
      previousItemWasABannerOrPage = 1;
      inDiv = 1;
    } else if (linkType == 'P' && text) { // Page
      if (!previousItemWasABannerOrPage) {
        if (inDiv) {
          // End current block
          menuItem += '</div>';
        }
        menuItem += '<div class="megaMenuContentBlock menu-column menu-page-column">';
      }
      menuItem += '<div class="ffPage">' + text + '</div>';
      menuItem = menuItem.replace('href="', 'href="' + skinJS_SiteURL + '/');
      previousItemWasABannerOrPage = 1;
      inDiv = 1;
    } else if (linkType == 'sH' && text) { // Sub-heading
      if (link) {
        link =
          '<a data-content="' +
          text +
          '" href="' +
          skinJS_SiteURL +
          '/' +
          link +
        '" class="ga-menu-click" data-ga-event-section="' + menuSectionTitle +'">' +
          text +
          '</a>';
      }
      else {
        link = text;
      }
      if (getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
        if (!link.match(/^<a /)) link = '<span>' + link + '</span>';
        if (nextItemLinkLevel == 1 && linkLevel != 1) { // Top level menu item
          newSubMenu = 1;
          menuID++;
          menuItem +=
            '<li class="dropdown-header' +
            addClass +
            '" data-menuid="submenu' +
            menuID +
            '"><span class="back-button" style="display:none"><ion-icon class="arrow-left" name="chevron-back-outline"></ion-icon></span><span>' +
            text +
            '</span><ion-icon class="arrow-right" name="chevron-forward-outline"></ion-icon></span></li>';
        }
        else if (nextItemLinkLevel != 1 && linkLevel != 1) {
          // Top level menu item with no child items, so no submenu
          menuItem += '<li class="shopAll">' + link + '</li>';
        }
        else {
          menuItem += '<li class="ffSubHeading' + addClass + '">' + link + '</li>';
        }
      } else {
        menuItem += '<strong class="ffSubHeading">' + text + '</strong>';
      }
      previousItemWasABannerOrPage = 0;
    } else if (linkType == 'H' && text) {
      // Start new block
      addClass = '';
      if (
        text == 'Sale' ||
        text == 'Special Offers' ||
        text == 'Outlet' ||
        text == 'Christmas'
      ) {
        addClass = ' saleHeading';
      }
      // linkType will be set to link for reasons
      if (link) {
        link = '<a data-content="' + text + '" href="' + skinJS_SiteURL + '/' + link + '" class="ga-menu-click" data-ga-event-section="' + menuSectionTitle +'">' + text + '</a>';
      }
      else {
        link = text;
      }

      if (getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
        if (!link.match(/^<a /)) link = '<span>' + link + '</span>';
        if (nextItemLinkLevel == 1 && linkLevel != 1) {
          // Top level menu item
          newSubMenu = 1;
          menuID++;
          menuItem +=
            '<li class="dropdown-header' +
            addClass +
            '" data-menuid="submenu' +
            menuID +
            '"><span class="back-button" style="display:none"><ion-icon class="arrow-left" name="chevron-back-outline"></ion-icon></span><span>' +
            text +
            '</span><ion-icon class="arrow-right" name="chevron-forward-outline"></ion-icon></span></li>';
        }
        else if (nextItemLinkLevel != 1 && linkLevel != 1) {
          // Top level menu item with no child items, so no submenu
          menuItem += '<li class="shopAll">' + link + '</li>';
        }
        else {
          addClass += ' submenu' + menuID;
          menuItem += '<li class="ffHeading' + addClass + '">' + link + '</li>';
        }
      } else {
        menuItem += '<div class="megamenu-section-heading"><strong class="ffHeading ' + addClass + '">' + link + '</strong></div>';
      }
      previousItemWasABannerOrPage = 0;
    } else {
      // Menu item
      var linkPrefix = skinJS_SiteURL + '/';
      if (linkType == 'eL') {
        linkPrefix = '';
      }
      if (getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
        if (nextItemLinkLevel == 1 && linkLevel != 1) {
          // Top level menu item
          newSubMenu = 1;
          menuID++;
          menuItem +=
            '<li class="dropdown-header" data-menuid="submenu' +
            menuID +
            '"><span class="back-button" style="display:none"><ion-icon class="arrow-left" name="chevron-back-outline"></ion-icon></span><span>' +
            text +
            '</span><ion-icon class="arrow-right" name="chevron-forward-outline"></ion-icon></span></li>';
        }
        else if (nextItemLinkLevel != 1 && linkLevel != 1) {
          // Top level menu item with no child items, so no submenu
          menuItem +=
            '<li class="shopAll"><a data-content="' +
            text +
            '" href="' +
            linkPrefix +
            link +
            '" class="mmLink ga-menu-click" data-ga-event-section="' + menuSectionTitle +'">' +
            text +
            '</a></li>';
        }
        else {
          menuItem +=
            '<li class="' +
            addClass +
            '"><a data-content="' +
            text +
            '" href="' +
            linkPrefix +
            link +
            '" class="mmLink ga-menu-click" data-ga-event-section="' + menuSectionTitle +'">' +
            text +
            '</a></li>';
        }
      } else {
        menuItem +=
          '<a data-content="' +
          text +
          '" href="' +
          linkPrefix +
          link +
          '" class="mmLink' +
          addClass +
          ' ga-menu-click" data-ga-event-section="' + menuSectionTitle +'">' +
          text +
          '</a>';
      }
      previousItemWasABannerOrPage = 0;
    }
  }
  return [menuItem, inDiv, newSubMenu, previousItemWasABannerOrPage, screenSizeMatches];
}
