// *****************************************************************
//JS: lovell-chooser-page.js
// Lovell Rugby website functions for chooser pages
// (c) 2017 Lovell Rugby Limited

$(function() {  
  setFavouritedItems();
});

function setFavouritedItems() {
  //
  var localFavourites = localStorage.getItem('favourites') || '';
  $('.item-image').each(function () {
    var wishlistButton = $(this).find('.wishlist-button');
    var productID = wishlistButton.attr('data-productid');
    var regex = new RegExp("\\b" + productID + "\\b", "i");
    if (productID && localFavourites.match(regex) && !wishlistButton.hasClass('active')) {
      wishlistButton.addClass('active');
    }
  });
}