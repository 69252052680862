// *****************************************************************
//JS: lovell-checkout.js
// Lovell Rugby website functions for the checkout
// (c) 2017 Lovell Rugby Limited

$(function() {
  // check content of gift message
  $('.btn-basket-checkout a').on('click', function(e) {
    // if partner club has been entered - make sure it exists as a 'Partner Club' tag
    if($('input[name=partnerclub]').length && $('input[name=partnerclub]').val().length) {
      e.preventDefault();
      var href = $(this).attr('href');
      var clubname = $('input[name=partnerclub]').val();
      doAjaxAction({ action: 'PARTNER_CLUB_EXISTS', clubname: clubname }, function(output) {
        if(output) {
          // partner club found
          createCookie('partnerclub', clubname, 30);
          $('.partner-club-error').remove();
          window.location.href = href;
        } else {
          createCookie('partnerclub', '', 30);
          $('input[name=partnerclub]').css('border-color', 'red');
          $('.field-associate-club').parent().prepend('<div class="partner-club-error">Partner club not found</div>').show();
          $('.field-associate-club').closest('.basket-footer-item').find('.basket-footer-item-header').addClass('open');
        }
      });
    }
  });

  // saved address list in my account section
  // toggle the address on click of header
  $('.alt-address .address-ref h3').on('click', function() {
    $(this).closest('.alt-address').toggleClass('collapsed');
  });

  // hide the UK county selection box (created by the selectOrDie jquery plugin) if the original select is hidden
  if($('#UKCountySelector').is(':hidden')) {
    $('#UKCountySelector').closest('.sod_select').hide();
  }
  if($('#del_UKCountySelector').is(':hidden')) {
    $('#del_UKCountySelector').closest('.sod_select').hide();
  }

  // hide the Irish county selection box (created by the selectOrDie jquery plugin) if the original select is hidden
  if($('#irishCountySelector').is(':hidden')) {
    $('#irishCountySelector').closest('.sod_select').hide();
  }
  if($('#del_irishCountySelector').is(':hidden')) {
    $('#del_irishCountySelector').closest('.sod_select').hide();
  }

  // if a replacement select box has no text in the label, add it from the first option
  $('.sod_select').each(function() {
    if(!$('.sod_label', this).text()) {
      $('.sod_label').text($(this).find('.sod_option:first-child').text());
    }
  });

  // hide the USA states selection box (created by the selectBoxIt jquery plugin) if the original select is hidden
  if($('#USAStateSelector').is(':hidden')) {
    $('#USAStateSelector').closest('.sod_select').hide();
  }
  if($('#del_USAStateSelector').is(':hidden')) {
    $('#del_USAStateSelector').closest('.sod_select').hide();
  }

  // toggle visibility of registration form
  $('#btn-register').on('click', function() {
    $('.registration-form').slideToggle('fast');
  });

  // change continue button green when valid email address entered
  $('#register-email').on('input', function() {
    if(emailIsValid($('#register-email').val())) {
      $('#btn-continue-register').css('background-color', '#009a20');
    } else {
      $('#btn-continue-register').css('background-color', '#585858');
    }
  });

  // hide the postcode lookup form for billing address when 'manually enter address' clicked
  $('.add_hideAddressInputsLink').on('click', function() {
    // show the manual entry form
    $('.add_AddressInputs').slideDown('fast');
    // hide the postcode lookup form
    $('#addPostcodeSearchForm').hide();
    // hide the 'manually enter address' link
    $(this).hide();
    // show the 'use billing address for delivery' button
    $('.use-billing-as-delivery').show();
  });
  // hide the postcode lookup form for delivery address when 'manually enter address' clicked
  $('.del_add_hideAddressInputsLink').on('click', function() {
    // show the manual entry form
    $('.del_add_AddressInputs').slideDown('fast');
    // hide the postcode lookup form
    $('#del_addPostcodeSearchForm').hide();
    // hide the 'manually enter address' link
    $(this).hide();
  });

  // add the is-valid class to country selector as it loads up with GB selected
  setTimeout(function() {
    setFieldValid($('#addCountry'));
    setFieldValid($('#del_addCountry'));
  }, 1000);

  $('#club-search').focus(function() {
    $('body').addClass('icr-club-search-active');
  });
  $('#club-search').on('input', function(e) {
    $(this).closest('.basket-footer-item-content').find('.partner-club-error').remove();
    $(this).css('border-color', '#b3b3b3');
  });
  $('#club-search').blur(function() {
    $('body').removeClass('icr-club-search-active');
  });
  $('.field-associate-club').on('click', '.taglink a', function(e) {
    e.preventDefault();
    $('#club-search').val($(this).text().trim());
    $('#club-search').closest('.basket-footer-item-content').find('.partner-club-error').remove();
    $('#club-search').css('border-color', '#b3b3b3')
    closeLiveSearch();
  });

  southAmericaDeliveryWarningToggle($('#addCountry').val());

  // login tabs (mobile only)
  $('.login-tab').on('click', function () {
    $('.login-tab').removeClass('active');
    $(this).addClass('active');
    $('.panel').removeClass('active');
    $('.' + $(this).data('panel')).addClass('active');
  });

  // select pay by card as default
  $(".field-control input").change();

}); // end doc ready

function validateGuestPassword(e) {
  $('#save-guest-password .field-error').remove();
  $('#save-guest-password #save-password').css('border-color', '#b3b3b3');
  $('#save-guest-password #repeat-password').css('border-color', '#b3b3b3');
  var isError = false;
  if(!$('#save-guest-password #save-password').val()) {
    $('#save-guest-password #save-password').css('border-color', 'red');
    $('#save-guest-password #save-password').closest('.field').before('<div class="field-error red" style="text-align: left; margin-left: 40%;">Please enter a password</div>');
    isError = true;
  }
  if(!$('#save-guest-password #repeat-password').val()) {
    $('#save-guest-password #repeat-password').css('border-color', 'red');
    $('#save-guest-password #repeat-password').closest('.field').before('<div class="field-error red" style="text-align: left; margin-left: 40%;">Please repeat your password</div>');
    isError = true;
  }
  if(($('#save-guest-password #save-password').val() != $('#save-guest-password #repeat-password').val()) && $('#save-guest-password #repeat-password').val()) {
    $('#save-guest-password #repeat-password').css('border-color', 'red');
    $('#save-guest-password #repeat-password').closest('.field').before('<div class="field-error red" style="text-align: left; margin-left: 40%;">Passwords do not match</div>');
    isError = true;
  }
  if(isError) {
    return false;
  }
}

function southAmericaDeliveryWarningToggle(country) {
// Show warning about customs charges if the user is sending to south america.

if (country == 'UY') {
  $('#uruguayDeliveryWarning').show();
}
else {
  $('#uruguayDeliveryWarning').hide();
}

if (country && country.match(/^(BO|CO|EC|GY|PY|PE|SR|UY|VE)$/)) { //AR|BR|CL
  $('#southAmericaDeliveryWarning').show();
}
else {
  $('#southAmericaDeliveryWarning').hide();
}
}