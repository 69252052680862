// *************************************************************
//JS: lovell-header.js
// Lovell Rugby website functions specific to the header
// (c) 2015 Lovell Rugby Limited
var skinJS_SiteCode = 'LR';
var skinJS_Language = 'en';

var jslocalstrings = [];

var screenWidth = $(window).width();

$(function() {

  // search bar slide down
  $('#search-form input').on('click', function (e) {
    e.stopPropagation();
  });
  $('.header-search').on('click', function () {
    if (getDeviceType() == 'mobile' || getDeviceType() == 'tablet') {
      // Close mega menu if open
      if ($('#navbar').hasClass('active')) {
        $('#menu-close').toggleClass('hidden');
        $('#menu-open').toggleClass('hidden');
        $('#navbar').removeClass('active');
        $('nav.navbar').removeClass('open');
        if (getDeviceType() == 'tablet') {
          $('nav.navbar').siblings('div').css('opacity', '1');
        }
      }

      $('#search-bar').toggleClass('active');
      $('body').toggleClass('search-active');
      if ($('body').hasClass('search-active')) {
        $('#drop-down-search').focus();
        $('#search').focus();
      } else {
        $('#drop-down-search').blur();
        $('#search').blur();
      }
    } else {
      $(this)
        .closest('.header-services')
        .toggleClass('search-active')
        .find('#search')
        .focus();
    }
  });
}); // end doc ready


// AJAX - Live search
var searchIsOn = 0;

function doLiveSearch(e) {
  var searchField = e.target.id;
  if (e.keyCode === 13) {
    searchRedirect(searchField);
  } else if (e.keyCode == 38 || e.keyCode == 40) {
    liveSearchScroll(e.target, 'livesearch', e);
  } else {
    // Wait for a fraction of a second for the key to be released, then get the search phrase (otherwise we'll be missing the most recent key-press)
    if (searchIsOn) { clearTimeout(searchIsOn) }
    searchIsOn = setTimeout("liveSearchLookup('" + searchField + "')", 250);

    if ((getDeviceType() == 'desktop' || getDeviceType() == 'wide') || ($(e.target).attr('id') == 'club-search')) {
      $('#livesearchresults').insertAfter($(e.target).parent());
    }
  }
}

var currentSearchPhrase;

function liveSearchLookup(searchField) {
  // This is in a function so we can call it independently of the keydown event
  var searchPhrase;

  if(document.getElementById(searchField)) {
    searchPhrase = document.getElementById(searchField).value;
  }

  if(searchPhrase.length <= 2) {
    // Close search box
    closeSearchBox(searchPhrase.length < 1);
  } else if(searchPhrase != currentSearchPhrase && searchPhrase != (currentSearchPhrase + ' ') && (searchPhrase + ' ') != currentSearchPhrase) {
    // Prevent unnecessary calling via AJAX
    var searchText = getVal(searchField);
    searchText = encodeURI(searchText);
    livesearch(searchText, ['livesearchresults']);
  }
  currentSearchPhrase = searchPhrase;
}

function closeSearchBox(force) {
  // Blank search results - without needing to perform an AJAX request
  var searchResults = document.getElementById('livesearchresults');
  if(searchResults) {
    searchResults.innerHTML = force ? '' : '<div id="livesearchtable"><div class="empty-text">Please enter some search text.</div></div>';
  }
}

function livesearch() {
  // AJAX call for Live Search
  var args = arguments;

  doAjaxAction({
    action: "dolivesearch",
    search: args[0],
    sitecode: skinJS_SiteCode
  }, function(output) {
    $("#"+args[1][0]).html(output);
 });

  $('#setUpScrollBars').remove();
  var checkIfFinished = setInterval(function() {
    if($('#setUpScrollBars').length) {
      $('#live-search-list').css({ 'padding-right': '10px' });
      setUpScrollBars();
      clearInterval(checkIfFinished);
    }
  }, 200);
}

var currentlyFocused = 0;

function liveSearchScroll(srcObj, scrollTable, e) {
  // Handles keyboard scrolling through live search/delivery address results
  var inputFieldID;
  var tableID;
  var linkClass;
  if(scrollTable == 'livesearch') {
    inputFieldID = 'search';
    tableID = 'livesearchtable';
    linkClass = 'livesearch';
  } else if(scrollTable == 'deliveryaddress') {
    inputFieldID = 'deliveryInputHeader';
    tableID = 'autoCompleteResultsHeader';
    linkClass = 'autoCompleteLinks';
  }
  var keyvalue = 0;
  if(e.type == 'keydown') {
    keyvalue = e.keyCode;
  }
  if(keyvalue == 38 || keyvalue == 40 || (scrollTable == 'deliveryaddress' && srcObj.id == inputFieldID && keyvalue == 13)) {
    // Prevent default page scrolling when up or down button is pressed
    if(e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }
  } else if(e.type == 'keydown' || e.type == 'keyup') {
    return;
  }
  var targetObj;
  var producttable = document.getElementById(tableID);
  var productlinks = [];
  if($(producttable).find('a').length) {
    var elements = producttable.getElementsByTagName('a');
    for(var i = 0; i < elements.length; i++) {
      if(elements[i].className == linkClass) {
        productlinks.push(elements[i]);
      }
    }
  }
  if(srcObj.id == inputFieldID && keyvalue == 40) { // We're in the searchbox, jump to first link when user presses 'down'
    targetObj = productlinks[0];
    currentlyFocused = 0;
  } else if(srcObj.id == inputFieldID && keyvalue == 38) { // We're in the searchbox, jump to last link when user presses 'up'
    targetObj = productlinks[productlinks.length - 1];
    currentlyFocused = productlinks.length - 1;
  } else if(srcObj.id == inputFieldID && productlinks[0] && keyvalue == 13) { // Pressed enter in the searchbox - visit first link in list, if any.
    window.location = productlinks[0].href;
  } else if((keyvalue == 40 && productlinks[currentlyFocused + 1] == undefined) || (keyvalue == 38 && (currentlyFocused - 1) < 0)) { // We're on the first list item, set focus back to the search box when user presses 'up'
    targetObj = document.getElementById(inputFieldID);
    currentlyFocused = 0;
  } else {
    if(e.type == 'mouseover') { // Set focus to any item the user hovers their mouse over
      currentlyFocused = Array.prototype.indexOf.call(productlinks, srcObj);
      targetObj = srcObj;
    } else if(keyvalue == 40) // Pressed 'down', jump to next item in list
    {
      currentlyFocused++;
      targetObj = productlinks[currentlyFocused];
    } else if(keyvalue == 38) // Pressed 'up', jump to previous item in list
    {
      currentlyFocused--;
      targetObj = productlinks[currentlyFocused];
    }
  }
  if(targetObj) {
    targetObj.focus();
    if(scrollTable == 'livesearch') {
      $('#' + tableID).find('div.media-body').css('background-color', '#ffffff');
      if(targetObj.id != inputFieldID) {
        $(targetObj).parent().css('background-color', '#f2fbfe');
      }
    } else {
      $('#' + tableID).find('a').css('background-color', 'transparent');
      if(targetObj.id != inputFieldID) {
        $(targetObj).css('background-color', '#f2fbfe');
      }
    }
  }
}

function closeLiveSearch() {
  var ls = document.getElementById('livesearchresults');
  if(ls) { ls.innerHTML = '' }
}

function searchPos() {
  // Position live search div
  var sbdiv;
  if(document.getElementById) sbdiv = document.getElementById('search');
  else if(document.all) sbdiv = document.all['search'];
  else if(document.layers) sbdiv = document.layers['search'];

  var lsdiv;
  if(document.getElementById) lsdiv = document.getElementById('livesearchresults');
  else if(document.all) lsdiv = document.all['livesearchresults'];
  else if(document.layers) lsdiv = document.layers['livesearchresults'];

  findPos(sbdiv);

  var shiftLeft = 0;
  var shiftDown = 24;
  if(skinJS_SiteCode == 'LS') {
    shiftLeft = 81;
    shiftDown = 30;
  }

  if(lsdiv) {
    lsdiv.style.left = (posleft - shiftLeft) + 'px';
    lsdiv.style.top = (postop + shiftDown) + 'px';
  }
}

function searchRedirect(id) {

  //Preventing search from submitting on the Club search page
  if ($("#search").hasClass('club-search')) return;

  var searchString = document.getElementById(id).value;
  var safeSearchString = searchString.replace(/^\s*/, "").replace(/\s*$/, "");
  safeSearchString = safeSearchString.replace(/\s{2,}/, " ");
  safeSearchString = safeSearchString.replace(/[-]/g, ' ');
  var unicodeRegex = js_unicoderegex(/[^\p{Lu}0-9 :\/\.,]/g);
  var unicodeRegexString = unicodeRegex.source;
  unicodeRegexString = unicodeRegexString.replace(/[\[\]]/g, '');
  unicodeRegexString = '[' + unicodeRegexString + ']';
  var useUnicodeRegex = new RegExp(unicodeRegexString, 'gi');
  safeSearchString = safeSearchString.replace(useUnicodeRegex, '');
  safeSearchString = safeSearchString.replace(/[\s]/g, '-');
  safeSearchString = safeSearchString.replace(/-{2,}/g, "-");
  safeSearchString = safeSearchString.replace(/-{2,}/g, "-");
  safeSearchString = safeSearchString.replace(/\//g, "%252F");
  if(searchString.length > 1 && safeSearchString.match(/\w/)) {
    window.location = skinJS_SiteURL + '/search/' + safeSearchString;
  }
}
